@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  height: 100%;
  min-height: 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  &__content {
    width: 100%;
    height: 100%;
    padding-inline: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;

    &--crypto {
      padding-bottom: 16px;
    }

    @include hide-scrollbar;
  }
}
