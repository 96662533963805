@import "@/styles/theme/main.scss";
.lottie-skeleton,
.text-skeleton {
  @include skeleton($secondary: true);
}

.lottie-skeleton {
  width: 150px;
  height: 150px;
  min-height: 150px;
  border-radius: 50%;
}

.text-skeleton {
  width: 100%;
  height: 16px;
}

.text-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}
