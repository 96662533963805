@import "@/styles/theme/main.scss";
.button,
.title,
.label {
  @include skeleton($secondary: true);
}

.button {
  width: 32px;
  height: 32px;
  border-radius: $border-radius;
}

.title {
  width: 80%;
  height: 24px;
  border-radius: 2px;
}

.label {
  width: 120px;
  height: 14px;
  border-radius: $border-radius;
}
