@import "@/styles/theme/main.scss";
.icon {
  cursor: pointer;
  transition: color 0.25s ease-in-out;
  font-size: 14px;
  color: $text-secondary;
  position: absolute !important;
  right: 16px;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  transition: color 0.25s ease-in-out;

  &:hover {
    color: $text-primary;
  }
}
