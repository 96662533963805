@import "@/styles/theme/main.scss";
.label,
.value {
  @include skeleton($secondary: true);
  border-radius: $border-radius;
  height: 14px;
}

.label {
  width: 80px;
}

.value {
  width: 60px;
}
