@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__content {
    display: flex;
    align-items: center;
    gap: 4px;

    &__page {
      all: unset;
      box-sizing: border-box;
      width: 32px;
      height: 32px;
      border-radius: $border-radius;
      background-color: $background-paper;
      cursor: pointer;
      display: flex;
      color: $text-secondary;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      transition:
        color 0.25s ease-in-out,
        filter 0.25s ease-in-out;
      font-weight: 600;

      &:disabled {
        pointer-events: none;
      }

      &:hover {
        color: $text-primary;
      }

      &--secondary {
        background-color: $background-default;
      }

      &--active {
        background-color: $card-color;
        color: $text-primary;
      }
    }
  }
}
