@import "@/styles/theme/main.scss";
.card {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border-radius: $border-radius;
  background-color: $primary-ghost-color;

  &__lottie {
    width: 100%;
    max-width: 100px;
    margin-inline: -18px;
  }

  font-size: 12px;
  font-weight: 500;
  color: $text-primary;
}
