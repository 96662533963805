@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  height: 58px;
  background-color: $background-paper;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 110;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 1fr;

  align-items: center;
}
