@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  height: 100%;
  min-height: 1px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;

  &__content {
    width: 100%;
    height: 100%;
    padding-inline: 16px;
    overflow-y: auto;
    @include hide-scrollbar;
  }

  &__button {
    min-height: 48px;
  }

  &__padded {
    width: 100%;
    padding-inline: 16px;
  }
}
