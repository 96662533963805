@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__description {
    font-size: 14px;
    font-weight: 500;
    color: $text-secondary;
    text-align: center;
  }

  &__button {
    all: unset;
    box-sizing: border-box;
    width: 48px;
    min-width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $card-color;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-secondary;
    transition:
      background 0.25s ease-in-out,
      filter 0.25s ease-in-out;
    cursor: pointer;
    position: relative;

    & i {
      transition: color 0.25s ease-in-out;
      font-size: 24px;
      transform-origin: center;
      position: relative;
      z-index: 2;
    }

    @keyframes thumb-up {
      0% {
        transform: scale(1) rotate(0deg);
      }
      30% {
        transform: scale(1.2) rotate(-15deg);
      }
      60% {
        transform: scale(1) rotate(0deg);
      }
      100% {
        transform: scale(1) rotate(0deg);
      }
    }

    @keyframes thumb-down {
      0% {
        transform: scale(1) rotate(0deg);
      }
      30% {
        transform: scale(1.2) rotate(15deg);
      }
      60% {
        transform: scale(1) rotate(0deg);
      }
      100% {
        transform: scale(1) rotate(0deg);
      }
    }

    @keyframes particle-animation {
      0% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(0);
      }
      90% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1);
      }

      100% {
        opacity: 0;
      }
    }

    &--positive {
      & i {
        transform: rotateY(180deg);
      }

      &:hover,
      &--selected {
        color: $success-color;
      }

      &--selected {
        animation: thumb-up 0.5s forwards;
      }

      .container__button__particle-container__particle {
        background-color: $success-color;
      }
    }

    &--negative {
      & i {
        transform: rotateX(180deg);
      }

      &:hover,
      &--selected {
        color: $error-color;
      }

      &--selected {
        animation: thumb-down 0.5s forwards;
      }

      .container__button__particle-container__particle {
        background-color: $error-color;
      }
    }

    &:hover {
      @include lighen(25%);
    }

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background-color: $card-color;
      z-index: 1;
      transition: background 0.25s ease-in-out;
      border-radius: 50%;
    }

    &__particle-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      overflow: visible;
      z-index: 0;

      &__particle {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 150%;
        height: 150%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        animation: particle-animation 1s forwards;
      }
    }
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}
