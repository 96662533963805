@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  max-width: 450px;
  height: 100dvh;
  background-color: $background-paper;
  border-left: 1px solid $divider-color;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  top: 0px;
  right: 0px;

  @include media-phone {
    width: 100dvw;
    height: 100dvh;
  }

  &__overlay {
    position: fixed;
    inset: 0;
    z-index: 999999999;
  }

  &__content {
    width: 100%;
    height: 100%;
    min-height: 1px;
    padding: 24px;

    @include media-phone {
      padding: 16px;
    }

    &--paddingless {
      padding: 0px !important;
    }
  }
}
