@import "@/styles/theme/main.scss";
.container {
  padding: 16px;
  position: relative;
  width: 400px;
  border-radius: $border-radius;
  background-color: $card-color;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  @include media-tablet {
    width: 358px;
  }

  &--row {
    width: 100%;

    &--v2 {
      border-radius: unset;
      padding: 24px;

      @include media-tablet {
        padding: 16px;
      }
    }
  }

  &--v3,
  &--Info,
  &--Error,
  &--Success {
    &::before {
      content: '';
      inset: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: $border-radius;
      opacity: 0.2;
    }
  }

  &--v3 {
    &::before {
      background: linear-gradient(to right, $primary-color 0%, transparent 40%);
    }
  }

  &--Info {
    &::before {
      background: linear-gradient(to right, $warning-color 0%, transparent 40%);
    }
  }

  &--Error {
    &::before {
      background: linear-gradient(to right, $error-color 0%, transparent 40%);
    }
  }

  &--Success {
    &::before {
      background: linear-gradient(to right, $success-color 0%, transparent 40%);
    }
  }

  &--toasty {
    z-index: 9999999999999 !important;
  }

  &__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 6px;
    background-color: $primary-color;
    border-bottom-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    max-width: 100%;
    transition: width 0.25s linear;
    z-index: 1;

    &--Info {
      background-color: $warning-color;
    }

    &--Error {
      background-color: $error-color;
    }

    &--Success {
      background-color: $success-color;
    }
  }

  &__close-button {
    position: absolute;
    right: -5px;
    top: -5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__icon {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $background-default;
      cursor: pointer;
      display: flex !important;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: $text-secondary;
      transition: filter 0.25s ease-in-out;
      position: relative;
      z-index: 1;

      &:hover {
        @include lighen(20%);
      }
    }

    &__progress {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      z-index: 0;

      & > div {
        width: 24px;
        height: 24px;
        z-index: 0;
      }
    }
  }
}
