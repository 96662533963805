@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;

  &__progress {
    display: flex;
    width: 80%;
    justify-content: space-between;

    &__force {
      width: 32%;
      height: 8px;
      border-radius: $border-radius;
      background-color: $card-color;
      transition: background-color 0.2s ease;
    }

    &__force--danger {
      background-color: $error-color;
    }
    &__force--warning {
      background-color: $warning-color;
    }

    &__force--success {
      background-color: $success-color;
    }
  }

  &__label-force {
    font-size: 12px;
    font-weight: 600;
    transition: color 0.2s ease;
    max-width: 20%;

    @include media-phone {
      font-size: 10px;
    }

    &--danger {
      color: $error-color;
    }
    &--warning {
      color: $warning-color;
    }
    &--success {
      color: $success-color;
    }
  }
}
