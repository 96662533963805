@import "@/styles/theme/main.scss";
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $text-secondary;
  }

  &__lottie {
    width: 100%;
    max-width: 250px;

    @include media-tablet {
      max-width: 180px;
    }
  }
}
