@import "@/styles/theme/main.scss";
.wrapper {
  width: 100%;
  position: relative;

  &__button {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}
