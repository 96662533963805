@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 8px;

  &__value {
    font-size: 18px;
    font-weight: 700;
    color: $text-primary;
    text-align: center;
  }

  &__card {
    gap: 8px;
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    background-color: $card-color;
    border-radius: $border-radius;

    &--row {
      width: 100%;
      background-color: unset;
      border-radius: unset;
      padding: unset;
    }
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;

    &--between {
      justify-content: space-between;
    }

    &--small {
      width: fit-content;
      gap: 8px;
    }

    &--center {
      justify-content: center;
      gap: 8px;
    }
  }

  &__text {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    transition:
      color 0.25s ease-in-out,
      text-decoration 0.25s ease-in-out;

    &--secondary {
      color: $text-secondary;
    }

    &--primary {
      color: $text-primary;
    }

    &--link {
      width: 100%;
      max-width: 140px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      color: $primary-color !important;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: $secondary-color;
      }
    }
  }

  &__dot {
    width: 10px;
    min-width: 10px;
    height: 10px;
    border-radius: 50%;
    position: relative;
    z-index: 2;

    &--Pending {
      background-color: $warning-color;

      &::after {
        background-color: $warning-color;
      }
    }

    &--Paid,
    &--Approved {
      background-color: $success-color;

      &::after {
        background-color: $success-color;
      }
    }

    &--Rejected,
    &--Expired,
    &--Refunded,
    &--Fail {
      background-color: $error-color;

      &::after {
        background-color: $error-color;
      }
    }

    &::after {
      content: '';
      width: 8px;
      height: 8px;
      position: absolute;
      left: 50%;
      z-index: -1;
      top: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      animation: 1s cubic-bezier(0, 0, 0.2, 1) 0s infinite normal none running
        ping;
    }

    @keyframes pulse {
      0%,
      100% {
        width: 8px;
        height: 8px;
        opacity: 0;
      }
      50% {
        width: 20px;
        height: 20px;
        opacity: 0.3;
      }
    }
    @keyframes ping {
      75%,
      100% {
        transform: translate(-50%, -50%) scale(2.4);
        opacity: 0;
      }
    }
  }

  &__image {
    width: 18px;
    height: 18px;
  }
}
