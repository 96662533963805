@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-snap-type: y proximity;
  perspective: 1000px;
  position: relative;
  padding-top: calc(50% - 20px);
  padding-bottom: calc(50% - 20px);

  &--order-1 {
    order: 1;
  }
  &--order-2 {
    order: 2;
  }
  &--order-3 {
    order: 3;
  }

  @include hide-scrollbar;
}
