@import "@/styles/theme/main.scss";
.box,
.title,
.description,
.date {
  @include skeleton($secondary: true);
}

.box {
  &--6 {
    align-self: flex-start;
    min-width: 32px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  &--7 {
    width: 60px;
    min-width: 60px;
    height: 60px;
  }
}

.title {
  width: 100px;
  height: 14px;
}

.description {
  width: 210px;
  height: 12px;
}

.date {
  width: 100px;
  height: 12px;
}
