@import "@/styles/theme/main.scss";
.container {
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-height: 350px;
  background-color: $background-paper;
  z-index: 15;
  overflow: hidden;
  position: fixed;

  &__content {
    width: 100%;
    height: calc(100% - 32px);
    min-height: 1px;
    padding: 16px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      z-index: 3;
      height: 30%;
      pointer-events: none;
    }

    &::before {
      bottom: 0;
      background: linear-gradient(to top, $card-color -40%, transparent);
    }

    &::after {
      top: 48px;
      background: linear-gradient(to bottom, $card-color -40%, transparent);
    }
  }
}
