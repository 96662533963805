@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;

  &__paste {
    position: absolute !important;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: $text-secondary;
    transition: color 0.3s;

    &:hover {
      color: $text-primary;
    }
  }

  &__row {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    &--1col {
      grid-template-columns: 1fr;
    }
  }

  &__wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    @include media-tablet {
      display: flex;
      flex-direction: column;
    }
  }

  &__qr {
    &__container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 134px;
      gap: 16px;
      align-items: flex-end;

      @include media-tablet {
        grid-template-columns: 1fr;
      }

      &__column {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 4px;
      }
    }

    width: 100%;
    max-width: 134px;
    height: 134px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    background-color: $text-primary;
    margin: 0 auto;

    &__skeleton {
      @include skeleton();
      width: 102%;
      height: 102%;
    }
  }

  &__card {
    width: 100%;
    padding: 16px;
    background-color: $primary-ghost-color;
    display: flex;
    align-items: start;
    gap: 8px;
    font-size: 12px;
    border-radius: $border-radius;

    & p {
      font-weight: 400;
    }

    & i {
      color: $primary-color;
      font-size: 14px;
      margin-top: 3px;
    }

    & strong {
      font-weight: 600;
    }
  }
}

.icon,
.text {
  @include skeleton($secondary: true);
  border-radius: $border-radius;
}

.icon {
  width: 14px;
  height: 14px;
  border-radius: $border-radius;
}

.text {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  width: 100%;
  height: 14px;
}
