@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
  padding: 16px;
  transition: all 0.25s ease-in-out;
  background-color: $background-paper;

  &:hover {
    background-color: $primary-ghost-color;

    &__subtitle {
      color: $text-primary;
    }
  }

  &--selected {
    background-color: $primary-ghost-color;

    &:hover {
      @include lighen(10%);
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 500;
    color: $text-primary;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__subtitle {
    font-size: 12px;
    color: $text-secondary;
    transition: all 0.25s ease-in-out;
  }

  &__chip {
    font-size: 12px;
    border-radius: $border-radius;
    padding: 4px 8px;
    width: max-content;
  }
}
