@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__input {
    & i {
      color: $success-color !important;
    }
  }
}
