@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid $background-paper;
  border-radius: $border-radius;
  background-color: $background-paper;
  transition: border 0.25s ease-in-out;
  position: relative;
  cursor: pointer;

  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;

  &__row {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__image {
    width: 32px;
    object-fit: contain;
  }

  &:hover,
  &--checked {
    border: 1px solid $primary-color;
  }

  &--loading {
    pointer-events: none;
  }

  &__column {
    display: flex;
    flex-direction: column;

    &--gapped {
      gap: 4px;
    }
  }

  &__name {
    font-size: 12px;
    font-weight: 700;
    color: $text-primary;
    display: block;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    gap: 8px;

    @include media-tablet {
      font-size: 12px;
    }
  }

  &__description {
    font-size: 10px;
    font-weight: 700;
    color: $text-secondary;

    &--primary {
      color: $text-primary;
    }
  }

  &__chip {
    padding: 4px 8px;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
    color: $primary-color;
    border-radius: $border-radius;
    text-transform: capitalize;
    background-color: $primary-ghost-color;
  }

  &__gateway {
    &__image {
      width: max-content;
      height: 12px;
    }
  }
}
