@import "@/styles/theme/main.scss";
.step {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: $text-secondary;
  cursor: pointer;

  &--disabled {
    pointer-events: none !important;
  }

  &:hover {
    & .step__indicator {
      border-color: $primary-color;
      color: $primary-color;
    }

    & .step__label {
      color: $text-primary;
    }
  }

  &__indicator {
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: $border-radius;
    border: 1px solid $text-secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition:
      border-color 0.3s,
      color 0.3s;
    line-height: 1rem;

    &--disabled {
      opacity: 0.7;
      color: $text-secondary;
      border-color: $text-secondary;
    }

    &--active {
      border: 1px solid $primary-color !important;
      color: $primary-color;
    }

    &__skeleton {
      @include skeleton();
      width: 30px;
      height: 30px;
    }
  }

  &__label {
    font-size: 14px;
    font-weight: 600;
    color: $text-secondary;
    white-space: nowrap;
    transition: color 0.3s;

    &__skeleton {
      @include skeleton();
      width: 120px;
      height: 14px;
    }

    &--active {
      color: $text-primary;
    }
  }

  &__divider {
    width: 100%;
    max-width: 30px;
    min-width: 20px;
    border-bottom: 2px solid $text-secondary;
  }

  &__marker {
    width: 100%;
    height: 4px;
    border-radius: $border-radius;
    background-color: $card-color;

    &--completed {
      background-color: $primary-color;
    }
  }
}
