@import "@/styles/theme/main.scss";
.row {
  font-size: 12px;
  font-weight: 400;
  color: $text-primary;
  word-break: break-all;
  overflow-wrap: break-word;

  & strong {
    color: $text-secondary;
    word-break: break-all !important;
    overflow-wrap: break-word !important;
  }

  & span {
    font-weight: 700;
    word-break: break-all !important;
    overflow-wrap: break-word !important;
    color: $text-secondary;

    & strong {
      color: $primary-color;
    }
  }

  & i {
    color: $primary-color;
  }
}

.skeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__text {
    width: 100%;
    height: 14px;

    &--small {
      width: 80%;
    }
  }
}
