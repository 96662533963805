@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;

    &--wrapped {
      justify-content: unset;
    }
  }

  &__title {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 24px;
    font-weight: 700;
    color: $text-primary;

    @include media-tablet {
      font-size: 18px;
    }
  }

  &__time {
    &__relative {
      font-size: 14px;
      color: $text-primary;
    }

    &__absolute {
      font-size: 14px;
      color: $text-secondary;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__icon {
    position: absolute !important;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: $text-secondary;
    transition: all 0.3s;

    &:hover {
      color: $text-primary;
    }
  }
}
