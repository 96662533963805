@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &__link {
    width: 100%;
  }

  &__button {
    all: unset;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;

    &__label,
    &__icon {
      transition: color 0.25s ease-in-out;
      text-align: center;
    }

    &__label {
      font-size: 14px;
      font-weight: 500;
      color: $text-secondary;
    }

    &__icon {
      font-size: 18px;
      transition: color 0.25s ease-in-out;
      color: $text-secondary;
    }

    &:hover {
      .container__button__icon {
        color: $primary-color;
      }

      .container__button__label {
        color: $text-primary;
      }
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.8;
    }
  }
}
