@import "@/styles/theme/main.scss";
.lottie,
.title,
.description {
  @include skeleton($secondary: true);
}

.lottie {
  width: 100%;
  max-width: 150px;
  height: 150px;
}

.title {
  width: 100%;
  max-width: 120px;
}

.description {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  width: 100%;

  &--half {
    width: 60%;
  }
}
