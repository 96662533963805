@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 16px;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  color: $text-secondary;
  color: $text-secondary;
  transition: all 0.25s ease-in-out;

  &:hover {
    background-color: $primary-ghost-color;
    color: $text-primary;
  }

  &--selected {
    color: $text-primary;
    background-color: $primary-ghost-color;
  }

  &--loading {
    pointer-events: none;
  }
}

.skeleton {
  @include skeleton($secondary: true);
  width: 20%;
  height: 14px;
}
