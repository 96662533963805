@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__title {
    font-size: 14px;
    font-weight: 600;
    color: $text-primary;
  }
}
