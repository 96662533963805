@import "@/styles/theme/main.scss";
.progress,
.label {
  @include skeleton($secondary: true);
}

.progress {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50%;
}

.label {
  width: 100%;
  height: 14px;
}
