@import "@/styles/theme/main.scss";
.icon {
  @include skeleton();
}

.icon--secondary {
  @include skeleton($secondary: true);
}

.icon,
.icon--secondary {
  width: 32px;
  height: 32px;
}
