@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__title {
    font-size: 18px;
    font-weight: 700;
    color: $text-primary;
  }

  &__description {
    font-size: 14px;
    font-weight: 500;
    color: $text-secondary;
  }

  &__link {
    color: $primary-color !important;
    transition:
      color 0.25s ease-in-out,
      text-decoration 0.25s ease-in-out;
    cursor: pointer;

    &:hover {
      color: $secondary-color !important;
      text-decoration: underline !important;
    }

    &--disabled {
      color: $text-secondary;
      pointer-events: none;
    }
  }
}
