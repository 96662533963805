@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: $background-default;
  padding: 16px;
  border-radius: $border-radius;
  overflow: hidden;

  &__column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    z-index: 1;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    color: $text-primary;
  }

  &__description {
    font-size: 14px;
    font-weight: 500;
    color: $text-secondary;
  }

  &__lottie {
    width: 250px;
    position: relative;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    transition: width 0.5s;
    background: linear-gradient(
      150deg,
      $primary-color,
      $background-default 45%
    );
  }
}
