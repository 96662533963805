@import "@/styles/theme/main.scss";
.address {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;
  word-break: break-all;
  color: $text-primary;
  font-size: 14px;
  font-weight: 400;
  gap: 8px;
  display: flex;
  align-items: center;
  position: relative;

  &__wrapper {
    padding: 8px;
    background-color: $background-paper;
    border-radius: $border-radius;
  }

  & strong {
    font-weight: 400;
    color: $primary-color;
  }
}
