@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  &__lottie {
    width: 100%;
    max-width: 100px;
  }

  &__title,
  &__description {
    width: 100%;
    max-width: 300px;
    text-align: center;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
  }

  &__description {
    font-size: 14px;
    color: $text-secondary;
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;

    @include media-tablet {
      flex-direction: column;
    }
  }

  &__column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &--small {
      gap: 4px;
    }

    &--center {
      align-items: center;
    }
  }

  &__text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  &__divider {
    width: 100%;
    height: 1px;
    background-color: $divider-color;
  }

  &__input {
    & i {
      color: $success-color !important;
    }
  }

  &__button {
    max-width: 300px !important;
  }

  &__spacer {
    margin-top: 16px;
  }
}
