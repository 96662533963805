@import "@/styles/theme/main.scss";
.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  @include media-tablet {
    flex-wrap: wrap;
  }
}

.column {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 4px;

  &--row {
    gap: 4px;
  }

  &__title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: $text-primary;
  }

  &__description {
    width: 100%;
    font-size: 11px;
    font-weight: 400;
    color: $text-secondary;

    & strong {
      font-weight: 700;
      color: $text-primary;
    }
  }

  &__time {
    font-size: 10px;
    font-weight: 500;
    color: $text-secondary;
    white-space: nowrap;
  }
}
