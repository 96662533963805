@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 8px;

  &__button {
    position: relative;
    font-size: 14px !important;
    font-weight: 600 !important;

    &__chip {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 40px;
      height: 16px;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary-contrast-text;
      background-color: $success-color;
      transition: opacity 0.25s ease-in-out;
      border-top-right-radius: $border-radius;

      &::after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        border: 7.5px solid transparent;
        border-left: 4px solid $background-paper;
        left: 0;
        top: 0;
      }

      &--selected {
        &::after {
          border-left-color: $primary-color;
        }
      }
    }
  }
}
