@import "@/styles/theme/main.scss";
.option {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: $background-default;
  padding: 16px;
  transition: all 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: $background-paper;
  }

  &--container-color {
    background-color: $background-paper;

    &:hover {
      background-color: $card-color;
    }
  }

  &--selected {
    background-color: $primary-ghost-color;

    &:hover {
      @include lighen(10%);
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    gap: 0.25rem;
  }

  &__image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  &__symbol {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    transition: color 0.25s ease-in-out;
  }

  &__name {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: $text-secondary;
  }
}
