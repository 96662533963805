@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  background-color: $background-default;

  &__column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  &__value {
    font-size: 22px;
    font-weight: 700;
    color: $text-primary;
    text-align: center;
    white-space: nowrap;
  }

  &__description {
    font-size: 14px;
    font-weight: 500;
    color: $text-secondary;
    text-align: center;
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: -16px;
  }

  &__coin {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  &__lottie {
    width: 100px;
    height: 100px;
    position: absolute;
    z-index: 1;
  }

  &__animated-coin {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &__coin {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      z-index: 1;
    }

    &__particle-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      overflow: visible;
      z-index: 0;

      @keyframes particle-animation {
        0% {
          opacity: 1;
          transform: translate(-50%, -50%) scale(0);
          border: 8px solid $warning-color;
        }

        25% {
          opacity: 1;
        }

        50% {
          opacity: 0.75;
        }

        100% {
          opacity: 0;
          transform: translate(-50%, -50%) scale(1);
          border: 18px solid $warning-color;
        }
      }

      &__particle {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        animation: particle-animation 1.25s infinite;
        background-color: rgba($warning-color, 0.2);
      }
    }
  }
}
