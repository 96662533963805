@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__card {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: $card-color;
    padding: 16px;
    border-radius: $border-radius;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    color: $text-primary;
  }
}
