@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: grid;
  grid-template-columns: 30% 1fr;
  gap: 8px;

  @include media-tablet {
    grid-template-columns: 1fr;
  }
}
