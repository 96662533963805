@import "@/styles/theme/main.scss";
.container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  background-color: $background-default;
  position: fixed;
  z-index: 99999999;
  overflow: hidden;

  @include media-tablet {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
}
