@import "@/styles/theme/main.scss";
.value,
.label {
  border-radius: $border-radius;
}

.value {
  @include skeleton($secondary: true);
  width: 20px;
  height: 20px;
}

.label {
  @include skeleton;
  width: 80%;
  height: 12px;
}
