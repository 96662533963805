@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  max-width: 500px;
  height: 90vh;
  max-height: 700px;
  background-color: $background-paper;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
  position: relative;

  @include media-tablet {
    max-width: calc(100% - 40px) !important;
  }

  &--small-mobile-padding {
    padding-bottom: 16px !important;
  }

  &--fit-content {
    max-height: 90vh;
    height: fit-content;
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 1px;
  }
}
