@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  padding: 16px;
  background-color: $background-paper;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 0;

  &--invisible {
    display: none !important;
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
  }

  &__column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;

    &--large {
      gap: 4px;
    }
  }

  &__value {
    font-size: 14px;
    font-weight: 700;
    color: $text-primary;

    &--light {
      font-weight: 500;
    }

    &--secondary {
      color: $text-secondary;
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    background-color: $divider-color;
  }
}
