@import "@/styles/theme/main.scss";
.image,
.name,
.description {
  @include skeleton($secondary: true);
}

.image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.name {
  width: 60px;
  height: 16px;
}

.description {
  width: 100px;
  height: 14px;
}
