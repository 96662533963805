@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    overflow-y: auto;
    z-index: 1;
    padding: 0 24px;
    min-height: 1px;

    @include hide-scrollbar;

    @include media-tablet {
      padding: 0 16px;
    }

    &__spacer {
      margin-top: 8px;
    }

    &__lottie {
      width: 150px;
    }

    &__description {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: $text-secondary;

      & strong {
        color: $text-primary;
      }
    }

    &__column {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }
  }

  &__button {
    width: 100%;
    padding: 0 24px;

    @include media-tablet {
      padding: 0 16px;
    }
  }

  &__row {
    width: 100%;
    display: grid;
    grid-template-columns: 30% 1fr;
    gap: 8px;

    @include media-tablet {
      grid-template-columns: 1fr;
    }
  }
}
