@import "@/styles/theme/main.scss";
.text {
  @include skeleton($secondary: true);
  width: 80px;
  height: 14px;
}

.value {
  @include skeleton($secondary: true);
  width: 120px;
  height: 18px;
}
