@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 8px;

  @include hide-scrollbar;

  &--gaplesss {
    gap: unset;
  }

  &__divider {
    width: 100%;
    height: 4px;
    border-top: 1px solid $divider-color;
  }
}
