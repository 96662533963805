@import "@/styles/theme/main.scss";
.container {
  width: calc(100% - 40px);
  max-width: 450px;
  height: 100%;
  min-height: 680px;
  max-height: 70vh;
  background-color: $background-default;
  border-radius: $border-radius;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  overflow: hidden;
  transition: height 0.25s ease-in-out;

  @media (max-height: 750px) {
    height: 90vh;
    min-height: auto;
    max-height: unset;
  }

  @include media-tablet {
    height: 90vh;
    min-height: auto;
    max-height: unset;
  }
}
