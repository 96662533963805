@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 1;
  position: relative;

  &::after {
    content: '';
    width: 1px;
    height: 85%;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    background-color: $text-secondary;
    z-index: 0;
  }
}
