@import "@/styles/theme/main.scss";
.checkbox {
  width: 18px;
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  border: 1px solid $divider-color;
  cursor: pointer;

  & i {
    display: none;
    font-size: 12px;
    color: $text-primary;
  }

  &--checked {
    background-color: $primary-color;

    & i {
      display: flex;
    }
  }

  &--large {
    width: 32px;
    min-width: 32px;
    height: 32px;

    & i {
      font-size: 16px;
    }
  }

  &--disabled {
    pointer-events: none;
  }
}

.skeleton {
  @include skeleton($secondary: true);

  width: 18px;
  min-width: 18px;
  height: 18px;

  &--large {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
}
