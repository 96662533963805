@import "@/styles/theme/main.scss";
.lottie--default,
.title--default,
.description--default {
  @include skeleton();
}

.lottie--secondary,
.title--secondary,
.description--secondary {
  @include skeleton($secondary: true);
}

.lottie {
  width: 100%;
  min-width: 120px;
  max-width: 120px;
  height: 120px;
  border-radius: 50%;
}

.title,
.description {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.title {
  width: 80px;
  height: 16px;
}

.description {
  height: 14px;
}
