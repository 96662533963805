@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  height: 100%;
  min-height: 1px;
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: $background-paper;

  &__header {
    width: 100%;
    padding: 16px;
    font-size: 16px;
    font-weight: 700;
    color: $text-primary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    &__wrapper {
      display: flex;
      align-items: center;
      gap: 8px;

      &__arrow {
        width: 30px;
        height: 25px;
        background-color: $background-default;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
        color: $text-secondary;
        border-radius: $border-radius;

        &:hover {
          background-color: $card-color;
          color: $text-primary;
        }
      }
    }

    &__close {
      width: 20px;
      height: 20px;
      background-color: $background-default;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $border-radius;
      transition: all 0.3s;
      color: $text-secondary;

      &:hover {
        background-color: $card-color;
        color: $text-primary;
      }
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    background-color: $background-default;
    padding-top: 16px;
    min-height: 1px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;

    &__wrapper {
      width: 100%;
      height: 100%;
      min-height: 1px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      &:not(.container__content__wrapper--disable-styles) {
        overflow-y: auto;
        @include hide-scrollbar;

        padding-inline: 16px;
      }
    }

    &__button {
      padding-inline: 16px;
    }
  }
}
