@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrapper {
    width: 100%;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__progress {
      &__container {
        height: 6px;
        border-radius: $border-radius;
        position: relative;
        background: $background-paper;
        width: 100%;
      }

      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-radius: $border-radius;
      background-color: $primary-color;
      transition: width 0.3s;
    }

    &__label {
      font-size: 12px;
      font-weight: 600;
      color: $text-primary;
      text-align: center;
    }
  }
}
