@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border-radius: $border-radius;
  background-color: $card-color;
  max-width: 300px;
  border-radius: $border-radius;
  padding: 16px;
  font-size: 14px;
  color: $text-primary !important;
  font-weight: 700;
  height: 48px;

  &__coin {
    display: flex;
    align-items: center;
    gap: 16px;

    &__image {
      width: 32px;
      height: 32px;
      border-radius: $border-radius;
    }

    &__amount {
      font-size: 14px;
      font-weight: 700;
    }
  }
}
