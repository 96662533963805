@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
  }

  &__label,
  &__value {
    font-size: 14px;
    font-weight: 500;
  }

  &__label {
    color: $text-secondary;
  }

  &__value {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $text-primary;
  }

  &__spinner {
    width: max-content;
    font-size: 14px;
  }
}
