@import "@/styles/theme/main.scss";
.row {
  width: 100%;
  max-width: 300px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 16px;

  & .row__wrapper:last-child .row__wrapper__card::after {
    display: none;
  }

  &--small {
    width: max-content;
    max-width: unset;
    grid-template-columns: repeat(3, 40px);
  }

  &--hide-days {
    width: max-content;
    max-width: unset;
    grid-template-columns: repeat(3, 1fr);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    &__card {
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $border-radius;
      background-color: $background-default;
      color: $text-primary;
      font-size: 16px;
      position: relative;
      font-weight: 600;

      &::after {
        content: ':';
        font-size: 23px;
        font-weight: 700;
        color: $text-primary;
        position: absolute;
        right: -16px;
        top: 50%;
        transform: translate(-4px, -50%);
      }
    }

    &__label {
      font-size: 12px;
      font-weight: 700;
      color: $text-secondary;
    }
  }
}
