@import "@/styles/theme/main.scss";
.icon,
.text {
  @include skeleton($secondary: true);
}

.icon {
  width: 20px;
  height: 20px;
}

.text {
  width: 40px;
  height: 14px;
}
