@import "@/styles/theme/main.scss";
.container {
  width: fit-content;
  height: 40px;
  min-height: 40px;
  padding: 0 16px;
  background: $background-paper;
  border-radius: $border-radius;

  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  gap: 8px;

  &__text {
    font-size: 14px;
    font-weight: 500;
    color: $text-primary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icon {
    font-size: 14px;
    color: $text-secondary;
  }

  &__link {
    all: unset;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    color: $text-secondary !important;
    cursor: pointer;
    transition: color 0.25s ease-in-out;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: $text-primary !important;
    }
  }

  @include media-tablet {
    &__text,
    &__icon,
    &__link {
      font-size: 12px;
    }
  }
}
