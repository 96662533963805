@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  &__lottie {
    width: 100%;
    max-width: 150px;
    height: 150px;
  }

  &__wrapper {
    all: inherit;
    gap: 8px;

    &__title,
    &__description {
      width: 100%;
      max-width: 280px;
      text-align: center;
    }

    &__title {
      font-size: 14px;
      font-weight: 700;
      color: $text-primary;
    }

    &__description {
      font-size: 12px;
      font-weight: 500;
      color: $text-secondary;
    }
  }
}
