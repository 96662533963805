@import "@/styles/theme/main.scss";
.cell {
  width: 100%;
  height: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: $text-secondary;
  scroll-snap-align: center;
  transform-style: preserve-3d;

  &--current {
    color: $text-primary;
    font-size: 20px;
    font-weight: bold;
    transform: rotateX(0deg) translateZ(0px);
  }
}
