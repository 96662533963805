@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    color: $text-primary;
    align-self: center;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 0px;
  }

  &__qrcode {
    width: 100%;
    max-width: 158px;
    height: 158px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    background-color: $text-primary;
    margin: auto;
    margin-block: 24px;
  }

  &__value {
    font-size: 18px;
    font-weight: 700;
    color: $text-primary;
    text-align: center;
    position: relative;
    padding: 8px 16px;
    background-color: $card-color;
    border-radius: $border-radius;
    z-index: 2;

    &::after {
      position: absolute;
      content: '';
      left: 50%;
      z-index: -1;
      bottom: -15px;
      width: 20px;
      height: 20px;
      background-color: $card-color;
      transform: rotate(45deg) translateX(-50%);
    }
  }

  &__text {
    width: 100%;
    font-size: 14px;
    color: $text-secondary;
    font-weight: 500;
  }

  &__icon {
    font-size: 14px;
    color: $text-secondary;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 16px;

    &__item {
      width: 100%;
      color: $text-secondary;
      font-size: 12px;
    }
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__divider {
    width: 100%;
    height: 1px;
    background-color: $divider-color;
  }

  &__alert {
    width: 100%;
    background-color: $card-color;
    color: $text-primary;
    font-size: 13px;
    font-weight: 700;
    padding: 16px;
    border-radius: $border-radius;
  }
}
