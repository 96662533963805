@import "@/styles/theme/main.scss";
.game {
  width: 100%;
  max-width: 150px;

  & a {
    min-width: 150px;

    @include media-tablet {
      min-width: unset;
    }
  }
}
