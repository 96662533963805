@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    gap: 16px;

    @include media-tablet {
      gap: 8px;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
  }

  &--error {
    padding: 8px;
    max-width: 450px;
    align-items: center;
  }
}
