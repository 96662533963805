@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  height: 100%;
  padding: 24px;

  @include media-tablet {
    min-height: 80vh;
  }
}
