@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: 2;

  &__indicator {
    width: 28px;
    min-width: 28px;
    height: 28px;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $card-color;
    color: $text-secondary;

    &--current {
      background-color: $secondary-color;
      color: $text-primary;
    }

    &--completed {
      background-color: $primary-color;
      color: $text-primary;
    }

    &--error {
      background-color: $error-color;
      color: $error-contrast-color;
    }

    @include media-tablet {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 2px;

    &__label {
      font-size: 14px;
      font-weight: 700;
      color: $text-primary;
    }

    &__description {
      font-size: 12px;
      font-weight: 400;
      color: $text-secondary;
    }

    &__link {
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      color: $primary-color;
      transition: all 0.25s ease-in-out;
      display: flex;
      align-items: center;
      gap: 8px;

      & div {
        width: 100%;
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:hover {
        color: $secondary-color;
      }
    }
  }
}
