@import "@/styles/theme/main.scss";
.container {
  border-radius: $border-radius;
  position: relative;
  background: $background-default;
  width: 100%;
  overflow: hidden;

  &::after {
    content: '';
    width: 200%;
    position: absolute;
    inset: 0;
    background: repeating-linear-gradient(
      45deg,
      $card-color,
      $card-color 10px,
      $background-paper 10px,
      $background-paper 20px
    );
    animation: spin 8s linear infinite;
  }

  &__progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: $border-radius;
    background-color: $primary-color;
    transition: width 0.3s;
    z-index: 1;

    &--customColor {
      background-color: var(--customColor);
    }

    &__skeleton {
      @include skeleton;
      position: absolute;
      inset: 0;
      width: 50%;
    }
  }

  &--useContainerColor {
    background-color: $background-paper;
  }

  &--card-color {
    background-color: $card-color;
  }

  &--tiny {
    height: 6px;
  }

  &--small {
    height: 12px;
    border-radius: calc($border-radius / 4);

    & .container__progress {
      border-radius: calc($border-radius / 4);
    }
  }

  &--medium {
    height: 24px;
    border-radius: 2px !important;

    & .container__progress {
      border-radius: 2px !important;
    }
  }

  &--large {
    height: 42px;
  }
}

@keyframes spin {
  0% {
    left: 0%;
  }
  100% {
    left: -100%;
  }
}
