@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;

  &__label {
    display: block;

    &--order-1 {
      order: 1;
    }

    &--order-2 {
      order: 2;
    }

    &--order-3 {
      order: 3;
    }
  }
}
