@import "@/styles/theme/main.scss";
.container {
  all: unset;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  font-size: 10px;
  font-weight: 500;
  color: $text-secondary !important;

  transition: color 0.25s ease-in-out;

  &--active {
    color: $primary-color !important;
  }

  &:disabled,
  &--disabled {
    pointer-events: none;
  }

  &--hide {
    color: $text-secondary !important;
  }

  &:hover {
    color: $text-primary !important;
  }

  & i {
    font-size: 18px;
  }

  &__icon {
    &--detached {
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 8px;
      position: relative;

      &::before {
        content: '';
        width: 60px;
        height: 60px;
        position: absolute;
        background-color: $background-paper;
        border-radius: 50%;
        z-index: 0;
      }

      & i {
        color: $primary-color;
        position: relative;
        font-size: 24px;
        z-index: 1;
      }
    }
  }
}
