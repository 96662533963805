@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  padding: 16px;
  background-color: $background-paper;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &--error {
    align-items: center;
    justify-content: center;
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
  }

  &__title {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 24px;
    font-weight: 700;
    color: $text-primary;

    @include media-tablet {
      font-size: 18px;
    }
  }
}
